import React from 'react';

const CustomAlert = ({ message, onClose }) => {
    return (
        <div className="custom-alert-overlay">
            <div className='container'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-md-6 col-12'>
                        <div className="custom-alert-box">
                            <p className="custom-alert-message">{message}</p>
                            <div className='d-flex justify-content-center'>
                                <button className="eye_btn p-2 ps-4 pe-4" onClick={onClose}>
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomAlert;
